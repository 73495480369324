import { ApiSpot } from './apiTypes'
import { Spot2 as Spot, PaginateSpots } from '~/models/Spot'
import { Marker2 as Marker } from '~/models/Marker'
import { ItinerarySpot2 as ItinerarySpot } from '~/models/ItinerarySpot'
import { ApiItinerarySpot } from '~/passporter-services/itinerary/apiTypes'
import { ItineraryEntityMapper } from '~/passporter-services/itinerary/entityMapper'

export class SpotEntityMapper {
	static toPaginatedSpots(data: any): PaginateSpots {
		const { next, results } = data
		const spots = this.toSpots(results)
		return { nextPage: next, spots }
	}

	static toSpots(spots: ApiSpot[]): Spot[] {
		return spots.map((spot: ApiSpot) => this.toSpot(spot))
	}

	static toMarker = (marker: any, checkValidate?: (marker: Marker) => boolean) => {
		const _marker = {
			id: marker.id,
			location: marker.location,
			color: marker.metadata?.colors?.[0],
			isReference: marker.metadata?.is_reference,
			icon: marker.icon,
		} as Marker
		_marker.isChecked = (checkValidate && checkValidate(marker)) || false
		return _marker
	}

	static toMarkers = (markers: any[], checkValidate?: (marker: Marker) => boolean) => {
		return markers.map((marker) => this.toMarker(marker, checkValidate))
	}

	static toSpot(spot: ApiSpot): Spot {
		return {
			address: spot.address,
			cover: spot.cover,
			description: spot.description,
			id: spot.id,
			location: spot.location,
			name: spot.name,
			numTravelers: spot.num_travelers,
			isPrivate: spot.state === 'private',
			travelersPictures: spot.travelers_pictures,
			type: spot.type,
			ownerId: spot.owner_id,
			parentSpotId: spot.parent_spot_id,
			visited: spot.visited,
			placeId: spot.place_id,
			parentId: spot.parent_id,
			mapMarker: spot.map_marker,
		}
	}

	static toItinerarySpot({
		spot,
		is_reference: isReference,
		groups,
		categories,
	}: ApiItinerarySpot): ItinerarySpot {
		const parsedSpot = this.toSpot(spot)
		const parsedGroups = groups && ItineraryEntityMapper.parseItineraryGroups(groups)
		const parsedCategories =
			categories && ItineraryEntityMapper.parseItineraryCategories(categories)
		return {
			...parsedSpot,
			isReference,
			groups: parsedGroups,
			categories: parsedCategories,
		}
	}

	static toItinerarySpots(itinerarySpots: any[]): ItinerarySpot[] {
		return itinerarySpots.map((itinerarySpot: ApiItinerarySpot) =>
			this.toItinerarySpot(itinerarySpot)
		)
	}
}
