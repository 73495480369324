import Block from './Block'
import { Category } from './Category'
import Destination from './Destination'
import Guide from './Guide'
import { serialize } from './helpers/serialize'
import Itinerary from './Itinerary'
import Media from './Media'
import Product from './Product'
import { SiteInfo } from './SiteInfo'

export interface ClientSite {
	userId: string
	name: string
	image?: string
}

export interface Stat {
	id: string
	name: string
	iconUrl: string
	percentage: string
}

export type Site2 = {
	id: string
	subdomain?: string
	destination: Destination
	views: number
	hasContent: boolean
	isOficial?: boolean
	client?: ClientSite
	stats?: Stat[]
	medias?: Media[]
	guideBlocks?: Block<Guide>[]
	itineraryBlocks?: Block<Itinerary>[]
	destinationBlocks?: Block<Destination>[]
	productBlock?: Block<Product>
	info?: SiteInfo[]
	categories?: Category[]
	filteredGuides?: Record<string, Guide[]>
}

export default class Site {
	id: string
	subdomain?: string
	destination: Destination
	views: number
	hasContent: boolean
	isOficial?: boolean
	client?: ClientSite
	stats?: Stat[]
	medias?: Media[]
	guideBlocks?: Block<Guide>[]
	itineraryBlocks?: Block<Itinerary>[]
	destinationBlocks?: Block<Destination>[]
	productBlock?: Block<Product>
	info?: SiteInfo[]
	categories?: Category[]
	filteredGuides?: Record<string, Guide[]>

	constructor(site: Site) {
		this.id = site.id
		this.subdomain = site.subdomain
		this.destination = serialize(Destination, site.destination)
		this.views = site.views
		this.hasContent = site.hasContent
		this.isOficial = site.isOficial
		this.client = site.client
		this.stats = site.stats
		this.medias = serialize(Media, site.medias)
		this.guideBlocks = serialize(Block, site.guideBlocks)
		this.itineraryBlocks = serialize(Block, site.itineraryBlocks)
		this.destinationBlocks = serialize(Block, site.destinationBlocks)
		this.productBlock = serialize(Block, site.productBlock)
		this.info = site.info
		this.categories = site.categories
		this.filteredGuides = site.filteredGuides
	}
}
