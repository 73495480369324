




































































































































import Vue, { PropOptions } from 'vue'
import { mapGetters } from 'vuex'
import SearchSelect from '~/components/UI/SearchSelect.vue'
import MosaicImages from '~/components/PassporterUI/MosaicImages.vue'
import SearcherCategory, { SearcherCategoryType } from '~/models/SearcherCategory'
import { Spot2 as Spot } from '~/models/Spot'
import Destination, { DestinationLocationData } from '~/models/Destination'
import Itinerary from '~/models/Itinerary'
import Row from '~/components/UI/Row.vue'
import Col from '~/components/UI/Col.vue'
import Cover from '~/components/PassporterUI/Cover.vue'
import EmptyState from '~/components/PassporterUI/EmptyState.vue'
import Spacer from '~/components/UI/Spacer.vue'
import POverlay from '~/components/PassporterUI/POverlay.vue'
import PIconChevron from '~/components/PassporterUI/Icon/PIconChevron.vue'
import PIconMapPin from '~/components/PassporterUI/Icon/PIconMapPin.vue'

export default Vue.extend({
	name: 'Searcher',
	components: {
		PIconMapPin,
		Col,
		PIconChevron,
		EmptyState,
		Cover,
		Spacer,
		Row,
		MosaicImages,
		POverlay,
		SearchSelect,
	},
	props: {
		categories: {
			type: Array,
			default: () => ['google', 'itinerary'],
		} as PropOptions<SearcherCategoryType[]>,
		input: {
			type: Boolean,
			default: false,
		},
		grow: {
			type: Boolean,
			default: false,
		},
		itemText: {
			type: String,
			default: undefined,
		},
		itemValue: {
			type: String,
			default: undefined,
		},
		error: {
			type: String,
			default: undefined,
		},
		name: {
			type: String,
			default: undefined,
		},
		label: {
			type: String,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: undefined,
		},
		value: {
			type: [Object, String],
			default: null,
		},
		returnObject: {
			type: Boolean,
			default: true,
		},
		showIcon: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Boolean,
			default: false,
		},
		hideMessages: {
			type: Boolean,
			default: false,
		},
		types: {
			type: Array,
			default: undefined,
		},
		v2: {
			type: Boolean,
			default: false,
		},
		level: {
			type: Number,
			default: undefined,
		},
		small: {
			type: Boolean,
			default: undefined,
		},
		bounds: {
			type: Object,
			default: undefined,
		} as PropOptions<DestinationLocationData>,
		isWizard: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			searchOptions: [],
			history: [],
			attention: false,
			isMounted: false,
			localSelected: undefined,
			itinerary: undefined as Itinerary | undefined,
			searchedText: '',
			categoriesList: [
				new SearcherCategory({
					id: 'google' as SearcherCategoryType,
					name: this.$t('discovery_search_destinations_title') as string,
					googleApi: true,
				}),
				new SearcherCategory({
					id: 'googleDestination' as SearcherCategoryType,
					name: this.$t('discovery_search_destinations_title') as string,
					googleApi: true,
				}),
				new SearcherCategory({
					id: 'place' as SearcherCategoryType,
					name: this.$t('discovery_search_spots_title') as string,
				}),
				new SearcherCategory({
					id: 'destination' as SearcherCategoryType,
					name: this.$t('discovery_search_destinations_title') as string,
				}),
				new SearcherCategory({
					id: 'itinerary' as SearcherCategoryType,
					name: this.$t('discovery_search_itineraries_title') as string,
				}),
				new SearcherCategory({
					id: 'traveler' as SearcherCategoryType,
					name: this.$t('discovery_search_travelers_title') as string,
					app: true,
				}),
			],
		}
	},
	computed: {
		...mapGetters({
			screenSize: 'screenSize',
			searchResults: 'searcher/searchResults',
			searchHistory: 'searcher/searchHistory',
			destinationsSearchResults: 'destination/searchResults',
			destinationHistory: 'destination/history',
			spotsSearchResults: 'spot/searchResults',
			spotsHistory: 'spot/history',
			itinerariesSearchResults: 'itinerary/searchResults',
			itinerariesHistory: 'itinerary/history',
			user: 'auth/user',
		}),
		availableCategories(): SearcherCategory[] {
			return this.categoriesList.filter((category: SearcherCategory) =>
				this.categories.includes(category.id)
			)
		},
		localItemText(): string {
			return this.itemText || 'name'
		},
		localItemValue(): string {
			return this.itemValue || 'id'
		},
		localReturnObject(): boolean {
			return this.input ? (this.itemValue ? false : this.returnObject) : true
		},
		selected: {
			get(): any {
				return this.value || this.localSelected
			},
			set(newValue: any) {
				const input = this.localReturnObject ? newValue : newValue?.[this.itemValue]
				this.localSelected = input
				this.$emit('input', input)
				this.$emit('change', newValue)
			},
		},
	},

	watch: {
		searchResults(searchResults, searchResultsOld) {
			if (searchResultsOld) {
				this.searchOptions = searchResults
			}
		},
	},
	created() {
		this.isMounted = true
	},
	beforeDestroy() {},
	methods: {
		async search(searchText: string, category: SearcherCategory) {
			let result = null
			this.searchedText = searchText
			if (!category) return

			if (['google', 'googleDestination'].includes(category.id)) {
				try {
					result = await this.obtainSearchOptions(category, searchText)
					this.searchOptions = this.searchResults
					this.history = this.searchHistory
				} catch (e: any) {}
			} else if (category.id === 'destination') {
				try {
					result = await this.$store.dispatch('destination/search', searchText)
					this.searchOptions = this.destinationsSearchResults
					this.history = this.destinationHistory
				} catch (e: any) {}
			} else if (category.id === 'place') {
				result = await this.$store.dispatch('spot/search', {
					search: searchText,
					googleApi: category.googleApi,
				})
				this.searchOptions = this.spotsSearchResults
				this.history = this.spotsHistory
			} else if (category.id === 'itinerary') {
				result = await this.$store.dispatch('itinerary/search', searchText)
				this.searchOptions = this.itinerariesSearchResults
				this.history = this.itinerariesHistory
			} else if (category.id === 'traveler') {
				// TODO: redirigir a vista traveler
			} else {
				this.searchOptions = []
				this.history = []
			}
			if (searchText) {
				this.addTracker(category.id, !!result?.length)
			}
		},
		async searchSelected(
			{
				item,
				category = undefined,
			}: {
				item: any
				category?: SearcherCategory
			},
			itinerary?: Itinerary
		) {
			if (item) {
				let route = null
				this.addSearchTracker(item)

				let searchedItem = item
				if (category?.googleApi) {
					try {
						searchedItem =
							category.id === 'googleDestination'
								? await this.$store.dispatch('destination/checkGDestination', item)
								: await this.$store.dispatch('spot/checkGPlace', item)
					} catch (e: any) {
						if (e.message === '400') {
							this.$emit('error', e)
						} else {
							this.clear()
						}
						return
					}
				}

				if (searchedItem) {
					this.addTrackerWhenIsMicrosite(searchedItem.name)
					if (this.input) {
						this.selected = searchedItem
						this.$mixpanel?.track('Input Search selected', {
							selected_name: searchedItem.name,
							google_place: Boolean(searchedItem.placeId) || null,
						})
					} else if (category) {
						this.$mixpanel?.track('Search selected', {
							selected_type: category.id,
							selected_name: searchedItem.name,
							google_place: Boolean(searchedItem.placeId) || null,
						})
						if (['google', 'googleDestination'].includes(category.id)) {
							await this.$store.dispatch('searcher/pushHistory', searchedItem)
							if (searchedItem.referenceType === 'spot') {
								this.goToSpot({
									spotId: searchedItem.id,
									itinerary,
								})
							} else if (searchedItem.referenceType === 'destination') {
								this.goToDestination({
									destinationId: searchedItem.id,
									itinerary,
								})
							}
						} else if (category.id === 'destination') {
							await this.$store.dispatch('destination/selected', item)
							route = `/${this.$i18n.locale}/destinations/${searchedItem.id}`
						} else if (category.id === 'place') {
							await this.$store.dispatch('spot/pushHistory', item)
							this.goToSpot({
								spot: searchedItem,
								itinerary,
								baseDomain: true,
							})
						} else if (category.id === 'itinerary') {
							await this.$store.dispatch('itinerary/selected', item)
							route = `/${this.$i18n.locale}/itineraries/${searchedItem.id}`
						} else if (category.id === 'traveler') {
							// TODO: redirigir a vista traveler
						}
						try {
							if (route) {
								await this.$router.push(route)
							}
						} catch (e: any) {}

						this.clear()
						await this.search('', category)
					}
				}
			} else {
				this.selected = null
			}
		},
		clear() {
			const searchSelect: any = this.$refs.searchSelect
			searchSelect.clear()
			this.selected = null
		},
		closed() {
			this.attention = false
			this.itinerary = undefined
		},
		goToSpot(data: {
			spot?: Spot
			spotId?: string
			itinerary?: Itinerary
			detail?: boolean
			baseDomain?: boolean
		}): void {
			if (this.user) {
				this.$store.dispatch('spot/openSpot', data)
			} else {
				this.$nuxt.$emit('open-register')
			}
		},
		goToDestination(data: {
			destination?: Destination
			destinationId?: string
			itinerary?: Itinerary
		}): void {
			if (this.user) {
				this.$store.dispatch('destination/openDestination', data)
			} else {
				this.$nuxt.$emit('open-register')
			}
		},
		isMicrosite(): boolean | undefined {
			const destinationRoute = 'destinations-destination_id'
			return this.$route.name?.includes(destinationRoute)
		},
		async obtainSearchOptions(category: any, searchText: string) {
			await this.$store.dispatch('searcher/search', {
				searchText,
				category,
				types: this.types,
				bounds: this.bounds,
			})
		},
		addSearchTracker(destination: any) {
			const context = this.$tracker.getContext(this.$route?.name)
			const params: Record<string, string> = {
				action: 'search destination',
				platform: 'web',
				type: 'destination',
				query: this.searchedText,
				id: destination.placeId,
				destination: destination.name,
			}
			if (context) params.context = context
			this.$tracker.event('search', params)
		},
		addTracker(categoryId: string, hasResults: boolean) {
			let eventName = 'Search Made'
			if (this.input) {
				eventName = 'Input Search Made'
			}
			this.$mixpanel?.track(eventName, {
				suggestion_type: categoryId,
				query_searched: this.searchedText,
				results: hasResults,
			})
		},
		addTrackerWhenIsMicrosite(searchedValue: string): void {
			if (this.isMicrosite() && !this.screenSize.xs) {
				this.$mixpanel?.track('Input', {
					page: 'microsites',
					context: 'header',
					value: searchedValue,
					component: 'search',
				})
			}
		},
	},
})
