import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Block from '~/models/Block'
import Guide from '~/models/Guide'
import Media from '~/models/Media'
import Site, { Stat } from '~/models/Site'
import { RootState } from '~/store/index'
import { serialize } from '~/models/helpers/serialize'
import Itinerary from '~/models/Itinerary'
import Product from '~/models/Product'
import { SiteInfo } from '~/models/SiteInfo'
import { Category } from '~/models/Category'
import SiteService from '~/passporter-services/site/service'

interface SiteInitState {
	site: Site | undefined
}
const initState = (): SiteInitState => ({
	site: undefined,
})
export const state = initState
export type SiteModuleState = ReturnType<() => SiteInitState>

export const mutations: MutationTree<SiteModuleState> = {
	serializeSite(state) {
		state.site = serialize(Site, state.site)
	},
	setSite(state, site) {
		state.site = site
	},
	setMedias(state, medias) {
		if (state.site) {
			state.site.medias = medias
		}
	},
	setStats(state, stats) {
		if (state.site) {
			state.site.stats = stats
		}
	},
	setGuides(state, guideBlocks) {
		if (state.site) {
			state.site.guideBlocks = guideBlocks
		}
	},
	setItineraries(state, itineraryBlocks) {
		if (state.site) {
			state.site.itineraryBlocks = itineraryBlocks
		}
	},
	setProducts(state, siteProductBlock) {
		if (state.site) {
			state.site.productBlock = siteProductBlock
		}
	},
	setInfo(state, siteInfo) {
		if (state.site) {
			state.site.info = siteInfo
		}
	},
	setCategories(state, categories: Category[]) {
		if (state.site) {
			state.site.categories = categories
		}
	},
	setFilteredGuides(state: any, { categoryId, guides }: { categoryId: string; guides: Guide[] }) {
		if (state.site) {
			const filteredGuides = state.site.filteredGuides ?? {}
			filteredGuides[categoryId] = guides
			state.site.filteredGuides = filteredGuides
		}
	},
	reset(state) {
		Object.assign(state, initState())
	},
}

export const actions: ActionTree<SiteModuleState, RootState> = {
	serialize({ commit }) {
		commit('serializeSite')
	},

	async getProducts({ commit, dispatch }, siteId) {
		try {
			const siteService = new SiteService(this.$repositories.site)
			const blocks = await siteService.getProductBlocks(siteId)
			const blockProduct = blocks[0]
			commit('setProducts', blockProduct)
		} catch (e) {
			await dispatch('error', e, { root: true })
		}
	},
	async getSite({ commit, dispatch }, id) {
		try {
			const siteService = new SiteService(this.$repositories.site)
			const parsedResult = await siteService.obtainBy(id)
			commit('setSite', parsedResult)
			if (!parsedResult.stats) {
				commit('setStats', null)
			}
			commit('setStats', parsedResult.stats)
		} catch (e) {
			commit('setSite', null)
			await dispatch('error', e, { root: true })
		}
	},

	async getMedias({ commit, dispatch }, id) {
		try {
			const medias = await this.$services.media.obtainBy(id)
			commit('setMedias', medias)
		} catch (e) {
			commit('setMedias', null)
			await dispatch('error', e, { root: true })
		}
	},

	async getGuides({ commit, dispatch }, id) {
		try {
			const siteService = new SiteService(this.$repositories.site)
			const guideBlocks = await siteService.obtainGuidesFromSite(id)
			commit('setGuides', guideBlocks)
		} catch (e) {
			commit('setGuides', null)
			await dispatch('error', e, { root: true })
		}
	},

	async getCompleteItineraries({ commit, dispatch }, id) {
		try {
			const blocksWithCreators = await this.$services.site.getItineraryBlocksWithCreators(id)
			commit('setItineraries', blocksWithCreators)
		} catch (e) {
			commit('setItineraries', null)
			await dispatch('error', e, { root: true })
		}
	},
	reset({ commit }) {
		commit('reset')
	},
}

export const getters: GetterTree<SiteModuleState, RootState> = {
	site: (state): Site | undefined => {
		return state.site
	},
	medias: (state): Media[] | undefined => {
		return state.site?.medias
	},
	stats: (state): Stat[] | undefined => {
		return state.site?.stats
	},
	guides: (state): Block<Guide>[] | undefined => {
		return state.site?.guideBlocks
	},
	itineraries: (state): Block<Itinerary>[] | undefined => {
		return state.site?.itineraryBlocks
	},
	productBlock: (state): Block<Product> | undefined => {
		return state.site?.productBlock
	},
	info: (state): SiteInfo[] | undefined => {
		return state.site?.info
	},
	getCategories(state): Category[] | undefined {
		return state.site?.categories
	},
	getFilteredGuides(state): Record<string, Guide[]> {
		return state.site?.filteredGuides ?? {}
	},
}
