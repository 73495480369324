export default class Calendar {
	private locale: string

	constructor(locale: string) {
		this.locale = locale
	}

	private formatDate(date: Date, options: Intl.DateTimeFormatOptions): string {
		return date.toLocaleDateString(this.locale, options).replace(',', ' ')
	}

	formatDateTime(datetime: string): string {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: datetime.match(/\d{2}:\d{2}/) ? '2-digit' : undefined,
			minute: datetime.match(/\d{2}:\d{2}/) ? '2-digit' : undefined,
		}
		return this.formatDate(new Date(datetime), options)
	}

	formatDateTimeFromDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		}
		return this.formatDate(new Date(date), options)
	}

	formatTime(datetime: string, value: string): string {
		const date = new Date(`${new Date(value).toDateString()} ${datetime}`)
		const options: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		}
		return date.toLocaleTimeString(this.locale, options)
	}

	formatTimeFromDate(datetime: Date): string {
		const options: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		}
		return datetime.toLocaleTimeString(this.locale, options)
	}

	formatDateOnly(datetime: string): string {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}
		return this.formatDate(new Date(datetime), options)
	}

	formatDateOnlyFromDate(datetime: Date): string {
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}
		return this.formatDate(datetime, options)
	}

	formatShowMothName(datetime: string): string {
		const currentYear = new Date().getFullYear()
		const dateTimeYear = new Date(datetime).getFullYear()
		const options: Intl.DateTimeFormatOptions = {
			month: 'short',
			day: '2-digit',
		}

		if (currentYear !== dateTimeYear) {
			options.year = 'numeric'
		}
		return this.formatDate(new Date(datetime), options)
	}

	static getDateString(date: Date): string {
		const year = date.getFullYear()
		const month = date.getMonth() + 1
		const day = date.getDate()
		return `${year}-${month}-${day}`
	}

	static getTimeString(date: Date): string {
		const hour = date.getHours()
		const minutes = date.getMinutes()
		const seconds = date.getSeconds()
		return `${hour}:${minutes}:${seconds}`
	}

	static getDateTimeString(date: Date): string {
		const dateString = this.getDateString(date)
		const timeString = this.getTimeString(date)
		return `${dateString}T${timeString}`
	}
}
