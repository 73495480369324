import Block, { BlockContentType } from '~/models/Block'
import Itinerary from '~/models/Itinerary'
import Traveler from '~/models/Traveler'
import BaseService from '~/services/BaseService'
import TravelerService from '~/services/Traveler'
import PassporterSiteService from '~/passporter-services/site/service'

export default class SiteService extends BaseService {
	async getItineraryBlocksWithCreators(
		id: string
	): Promise<Array<Block<BlockContentType>> | undefined> {
		const siteService = new PassporterSiteService(this.repositories.site)
		const itinerariesBlock = (await siteService.getItineraryBlocks(id)) as unknown as Array<
			Block<BlockContentType>
		>
		if (itinerariesBlock) {
			await Promise.all(
				itinerariesBlock.map(async (block: any) => {
					return await this.getBlockCreators(block)
				})
			)
		}

		return itinerariesBlock
	}

	async getBlockCreators(block: any): Promise<void> {
		await Promise.all(
			block.content.map(async (item: Itinerary) => {
				const traveler = new TravelerService(this.repositories, this.apiModel)
				item.creator = (await traveler.obtainBy(item.creatorId!)) as Traveler | null

				return item
			})
		)
	}
}
