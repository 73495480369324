import { SpotEntityMapper } from './entityMapper'
import { Spot2 as Spot, PaginateSpots } from '~/models/Spot'
export class SpotService {
	repository: any
	constructor(repository?: any) {
		this.repository = repository
	}

	async getSpot(spotId: string): Promise<Spot> {
		const res = await this.repository.getSpot(spotId)
		const { data } = res
		const spot = SpotEntityMapper.toSpot(data)
		return spot
	}

	async obtainSpots(destinationId: string, categoryId: string | null): Promise<PaginateSpots> {
		const res = await this.repository.getSpots({
			destinationId,
			categoryId,
			page: 0,
		})

		const { data } = res
		const paginatedSpots = SpotEntityMapper.toPaginatedSpots(data)
		return paginatedSpots
	}

	setExperiences(
		spot: Spot,
		experiences?: Spot[] | undefined,
		count?: number,
		nextPage?: number
	): void {
		spot.experiences = experiences
		spot.experiencesCount = count
		spot.experiencesNextPage = nextPage
	}

	pushSpotExperiences(spot: Spot, spotExperiences?: Spot[] | undefined, nextPage?: number): void {
		spotExperiences?.forEach((spotExperience: Spot) => {
			const indexed = spot.experiences?.find(
				(indexedSpotExperience: any) => indexedSpotExperience.id === spotExperience.id
			)
			if (!indexed) {
				spot.experiences?.push(spotExperience)
			}
		})
		spot.experiencesNextPage = nextPage
	}

	setNearbySpots(spot: Spot, nearbySpots?: Spot[] | undefined, nextPage?: number): void {
		spot.nearbySpots = nearbySpots
		spot.nearbySpotsNextPage = nextPage
	}

	pushNearbySpots(spot: Spot, nearbySpots?: Spot[] | undefined, nextPage?: number): void {
		nearbySpots?.forEach((nearbySpot: Spot) => {
			const indexed = spot.nearbySpots?.find(
				(indexedNearbySpot: Spot) => indexedNearbySpot.id === nearbySpot.id
			)
			if (!indexed) {
				spot.nearbySpots?.push(nearbySpot)
			}
		})
		spot.nearbySpotsNextPage = nextPage
	}
}
