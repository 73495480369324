import Guide from './Guide'
import Itinerary from './Itinerary'
import Spot from './Spot'
import Traveler from './Traveler'
import { serialize } from './helpers/serialize'
import Product from './Product'
import Destination from '~/models/Destination'

export type BlockContentType =
	| Spot
	| Itinerary
	| Traveler
	| Guide
	| Product
	| Destination
export type BlockType =
	| 'destination'
	| 'spot'
	| 'guide'
	| 'itinerary'
	| 'traveler'
	| 'product'

export default class Block<ContentType extends BlockContentType> {
	id: string
	title: string
	subtitle?: string
	header?: string
	description?: string
	image?: string
	type: BlockType
	button?: string
	link?: string
	content?: ContentType[]
	nextPage?: number
	origin?: string
	src?: string
	constructor(block: Block<ContentType>) {
		this.id = block.id
		this.title = block.title
		this.subtitle = block.subtitle
		this.header = block.header
		this.description = block.description
		this.image = block.image
		this.type = block.type
		this.button = block.button
		this.link = block.link
		if (block.type === 'guide') {
			this.content = serialize(Guide, block.content)
		} else if (block.type === 'itinerary') {
			this.content = serialize(Itinerary, block.content)
		} else if (block.type === 'destination') {
			this.content = serialize(Destination, block.content)
		} else if (block.type === 'product') {
			this.content = serialize(Product, block.content)
		} else {
			this.content = block.content
		}
		this.nextPage = block.nextPage
		this.origin = block.origin
		this.src = block.src
	}
}
